import { css } from 'styled-components';

import { rgba } from '@/styles/js/utils/colors';

export const absolute = css`
  content: '';
  display: block;
  position: absolute;
`;

export const shadow = (to = 'bottom') => {
  const pseudoShadowHeight = '0.9rem';

  return css`
    height: ${pseudoShadowHeight};
    background-image: linear-gradient(
      to ${to},
      ${rgba('black', 0.04)},
      ${rgba('black', 0)}
    );

    ${to === 'top' && css`
      top: -${pseudoShadowHeight};
    `}

    ${to === 'bottom' && css`
      bottom: -${pseudoShadowHeight};
    `}
  `;
};
