import z from 'zod';

let devEnv = {};

if (process.env.NODE_ENV === 'development') {
  devEnv = {
    SERVER_URL: z.string().default('http://localhost:3000'),
    API_URL: z.string().default('http://localhost:3375'),
    DASH_URL: z.string().default('http://localhost:3274'),
    DISABLE_HUBSPOT: z.coerce.boolean().default(true),
  };
}

export const publicEnvSchema = z.object({
  NODE_ENV: z
    .enum(['development', 'test', 'production'])
    .default('development'),

  ENVIRONMENT: z
    .enum(['local', 'staging', 'feature', 'production'])
    .default('local'),

  NEXT_RUNTIME: z.enum(['nodejs', 'edge', '']).optional(),

  SERVER_URL: z.string().optional(),
  API_URL: z.string().optional(),
  DASH_URL: z.string().optional(),

  MARKETING_SITE_URL: z
    .string()
    .default(
      process.env.NODE_ENV === 'production'
        ? 'https://www.deskpass.com'
        : 'https://marketing-staging.deskpass.com',
    ),

  FEEDS_API_URL: z.string().default('https://feeds.deskpass.com'),

  ANALYTICS_TRACKING_DISABLED: z.coerce.boolean().default(false),

  ANALYTICS_ENCRYPTION_KEY: z.string().optional(),

  STRIPE_KEY: z.string().optional(),
  STRIPE_IDENTITY_PRIVACY_POLICY_URL: z
    .string()
    .default(
      'https://support.stripe.com/questions/managing-your-id-verification-information',
    ),

  LOGROCKET_ID: z.string().optional(),
  LOG_ROCKET_SESSION_PERCENTAGE: z.coerce.number().default(25),
  LOGROCKET_LOCAL: z.coerce.boolean().default(false),

  OPENREPLAY_PROJECT_KEY: z.string().optional(),

  IP_GEOLOCATION_KEY: z.string().optional(),

  DEBUG_I18N: z.coerce.boolean().default(false),

  DEFAULT_RADIUS: z.coerce.number().default(50),

  MAX_DAILY_BOOKING_MONTHS: z.coerce.number().default(6),

  GOOGLE_MAPS_MAP_ID: z.string().optional(),
  GOOGLE_MAPS_API_KEY: z.string().optional(),

  ACTIVE_CRM: z.string().default('hubspot'),
  INTERCOM_APP_ID: z.string().optional(),

  HUBSPOT_APP_ID: z.string().optional(),
  HUBSPOT_URL: z.string().default('https://hs.deskpass.com'),
  DISABLE_HUBSPOT: z.coerce.boolean().default(false),

  ...devEnv,
});

/*
 * NOTE
 *
 * Imported into next.config.js env section to expose
 * the public environment variables to the client side
 * at runtime.
 */
export const rawEnv = {
  SERVER_URL: process.env.SERVER_URL,
  API_URL: process.env.API_URL,
  FEEDS_API_URL: process.env.FEEDS_API_URL,
  DASH_URL: process.env.DASH_URL,
  MARKETING_SITE_URL: process.env.MARKETING_SITE_URL,

  ENVIRONMENT: process.env.ENVIRONMENT,

  ANALYTICS_TRACKING_DISABLED: process.env.ANALYTICS_TRACKING_DISABLED,
  ANALYTICS_ENCRYPTION_KEY: process.env.ANALYTICS_ENCRYPTION_KEY,

  STRIPE_KEY: process.env.STRIPE_KEY,
  STRIPE_IDENTITY_PRIVACY_POLICY_URL:
    process.env.STRIPE_IDENTITY_PRIVACY_POLICY_URL,

  LOGROCKET_ID: process.env.LOGROCKET_ID,
  LOG_ROCKET_SESSION_PERCENTAGE: process.env.LOG_ROCKET_SESSION_PERCENTAGE,
  LOGROCKET_LOCAL: process.env.LOGROCKET_LOCAL,

  OPENREPLAY_PROJECT_KEY: process.env.OPENREPLAY_PROJECT_KEY,

  IP_GEOLOCATION_KEY: process.env.IP_GEOLOCATION_KEY,

  DEBUG_I18N: process.env.DEBUG_I18N,

  DEFAULT_RADIUS: process.env.DEFAULT_RADIUS,

  MAX_DAILY_BOOKING_MONTHS: process.env.MAX_DAILY_BOOKING_MONTHS,

  GOOGLE_MAPS_MAP_ID: process.env.GOOGLE_MAPS_MAP_ID,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,

  ACTIVE_CRM: process.env.ACTIVE_CRM,
  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,

  HUBSPOT_APP_ID: process.env.HUBSPOT_APP_ID,
  HUBSPOT_URL: process.env.HUBSPOT_URL,
  DISABLE_HUBSPOT: process.env.DISABLE_HUBSPOT,
};

/*
 *
 * Public environment variables.
 *
 * NOTE
 *
 * Every env var from process.env should be included here for type safety
 * and validation. This module env/index exposes the public env variables
 * and they all get exposed to the client side javascript bundle so beware!
 */
export const ENV = publicEnvSchema.parse({
  NODE_ENV: process.env.NODE_ENV,
  NEXT_RUNTIME: process.env.NEXT_RUNTIME,
  ...rawEnv,
});
