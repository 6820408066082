/*
 * Allows you to safely access nested properties and methods in an object.
 */
export function safeAccess() {
  const handler = {
    get: () => {
      // Return a proxy for all properties
      return safeAccess();
    },
    // Handle calling the proxy as a function
    apply: () => {
      // Return undefined or no-op when the proxy is called
      return undefined;
    },
  };

  // Create a callable proxy
  return new Proxy(() => undefined, handler);
}

export const isBrowser = typeof window !== 'undefined';
export const isServer = typeof window === 'undefined';

export const safeLocation =
  typeof location !== 'undefined' ? location : safeAccess();
export const safeWindow = typeof window !== 'undefined' ? window : safeAccess();
export const safeDocument =
  typeof document !== 'undefined' ? document : safeAccess();
export const safeNavigator =
  typeof document !== 'undefined' ? navigator : safeAccess();
